import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  { path: '/', redirect: 'Index' },
  {
    path: '/index',
    name: 'index',
    component: () => import('../components/index/Index.vue'),
	meta: {
		keepAlive: true, // 组件需要缓存
	}
	
  },
  {
    path: '/index1',
    name: 'index1',
    component: () => import('../components/index/Index1.vue'),
  	meta: {
  		keepAlive: true, // 组件需要缓存
  	}
  	
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/AboutView.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../components/login/Login.vue')
  },
  {
    path: '/repassword',
    name: 'repassword',
    component: () => import('../components/login/Repassword.vue')
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('../components/login/Register.vue')
  },
  {
    path: '/read',
    name: 'read',
    component: () => import('../components/index/Read.vue'),
	meta: {
		keepAlive: true, // 组件需要缓存
	}
  },
  {
    path: '/bonds',
    name: 'bonds',
    component: () => import('../components/index/Bonds.vue'),
  	// meta: {
  	// 	keepAlive: true, // 组件需要缓存
  	// }
  },
  {
    path: '/collect',
    name: 'collect',
    component: () => import('../components/index/Collect.vue')
  },
  {
    path: '/user',
    name: 'user',
    component: () => import('../components/index/User.vue'),
	meta: {
		keepAlive: true, // 组件需要缓存
	}
  },
  {
    path: '/nft/shop',
    name: 'shop',
    component: () => import('../components/nft/Shop.vue'),
  },
  {
    path: '/nft/shoplog',
    name: 'shoplog',
    component: () => import('../components/nft/Shoplog.vue'),
  },
  {
    path: '/nft/shops',
    name: 'shops',
    component: () => import('../components/nft/Shops.vue'),
  },
  {
    path: '/nft/pay',
    name: 'pay',
    component: () => import('../components/nft/Pay.vue')
  },
  {
    path: '/nft/notice',
    name: 'notice',
    component: () => import('../components/nft/Notice.vue'),
	// meta: {
	// 	keepAlive: true, // 组件需要缓存
	// }
  },
  {
    path: '/nft/newslist',
    name: 'newslist',
    component: () => import('../components/nft/Newslist.vue'),
	meta: {
		keepAlive: true, // 组件需要缓存
	}
  },
  {
    path: '/nft/newslist1',
    name: 'newslist1',
    component: () => import('../components/nft/Newslist1.vue'),
  	meta: {
  		keepAlive: true, // 组件需要缓存
  	}
  },
  {
    path: '/nft/nftlist',
    name: 'nftlist',
    component: () => import('../components/nft/Nftlist.vue')
  },
  {
    path: '/user/setting',
    name: 'setting',
    component: () => import('../components/user/Setting.vue'),
	meta: {
		keepAlive: true, // 组件需要缓存
	}
  },
  {
    path: '/user/edit',
    name: 'edit',
    component: () => import('../components/user/Edit.vue'),
	meta: {
		keepAlive: true, // 组件需要缓存
	}
  },
  {
    path: '/user/obuy',
    name: 'obuy',
    component: () => import('../components/user/Obuy.vue'),
  },
  {
    path: '/user/osell',
    name: 'osell',
    component: () => import('../components/user/Osell.vue'),
  },
  {
    path: '/user/password',
    name: 'password',
    component: () => import('../components/user/Password.vue')
  },
  {
    path: '/user/paypwd',
    name: 'paypwd',
    component: () => import('../components/user/Paypwd.vue')
  },
  {
    path: '/user/paypwd1',
    name: 'paypwd1',
    component: () => import('../components/user/Paypwd1.vue')
  },
  {
    path: '/user/paypwd2',
    name: 'paypwd2',
    component: () => import('../components/user/Paypwd2.vue')
  },
  {
    path: '/user/paypwds',
    name: 'paypwds',
    component: () => import('../components/user/Paypwds.vue')
  },
  {
    path: '/user/chats',
    name: 'chats',
    component: () => import('../components/user/Chats.vue')
  },
  {
    path: '/nft/collect',
    name: 'collects',
    component: () => import('../components/nft/Collect.vue'),
	meta: {
		keepAlive: true, // 组件需要缓存
	}
  },
  {
    path: '/nft/collect111',
    name: 'collect111',
    component: () => import('../components/nft/Collect111.vue'),
  	meta: {
  		keepAlive: true, // 组件需要缓存
  	}
  },
  {
    path: '/nft/collect2',
    name: 'collect2',
    component: () => import('../components/nft/Collect2.vue'),
  	meta: {
  		keepAlive: true, // 组件需要缓存
  	}
  },
  {
    path: '/nft/pledge',
    name: 'pledge',
    component: () => import('../components/nft/Pledge.vue'),
  	meta: {
  		keepAlive: true, // 组件需要缓存
  	}
  },
  {
    path: '/nft/withs',
    name: 'withs',
    component: () => import('../components/nft/Withs.vue'),
  },
  {
    path: '/user/otc',
    name: 'otc',
    component: () => import('../components/user/Otc.vue'),
  },
  {
    path: '/nft/collect3',
    name: 'collect3',
    component: () => import('../components/nft/Collect3.vue'),
  	meta: {
  		keepAlive: true, // 组件需要缓存
  	}
  },
  {
    path: '/nft/create',
    name: 'create',
    component: () => import('../components/nft/Create.vue')
  },
  {
    path: '/nft/createorder',
    name: 'createorder',
    component: () => import('../components/nft/Createorder.vue')
  },
  {
    path: '/nft/bus',
    name: 'bus',
    component: () => import('../components/nft/Bus.vue'),
  },
  {
    path: '/nft/buss',
    name: 'buss',
    component: () => import('../components/nft/Buss.vue'),
  },
  {
    path: '/user/recharge',
    name: 'recharge',
    component: () => import('../components/user/Recharge.vue')
  },
  {
    path: '/user/chargelog',
    name: 'chargelog',
    component: () => import('../components/user/Chargelog.vue')
  },
  {
    path: '/user/nftlog',
    name: 'nftlog',
    component: () => import('../components/user/Nftlog.vue')
  },
  {
    path: '/user/chargelogs',
    name: 'chargelogs',
    component: () => import('../components/user/Chargelogs.vue')
  },
  {
    path: '/user/c2c',
    name: 'c2c',
    component: () => import('../components/user/C2c.vue')
  },
  {
    path: '/user/otc1',
    name: 'otc1',
    component: () => import('../components/user/Otc1.vue')
  },
  {
    path: '/nft/plelog',
    name: 'plelog',
    component: () => import('../components/nft/Plelog.vue')
  },
  {
    path: '/user/with',
    name: 'with',
    component: () => import('../components/user/With.vue')
  },
  {
    path: '/user/otcs',
    name: 'otcs',
    component: () => import('../components/user/Otcs.vue')
  },
  {
    path: '/user/collect1',
    name: 'collect1',
    component: () => import('../components/user/Collect1.vue'),
  	meta: {
  		keepAlive: true, // 组件需要缓存
  	}
  },
  {
    path: '/user/assets',
    name: 'assets',
    component: () => import('../components/user/Assets.vue'),
	meta: {
		keepAlive: true, // 组件需要缓存
	}
  },
  {
    path: '/user/swap',
    name: 'swap',
    component: () => import('../components/user/Swap.vue'),
  },
  {
    path: '/user/team',
    name: 'team',
    component: () => import('../components/user/Team.vue')
  },
  {
    path: '/user/share',
    name: 'share',
    component: () => import('../components/user/Share.vue'),
	meta: {
		keepAlive: true, // 组件需要缓存
	}
  },
  {
    path: '/nft/Borrowing',
    name: 'borrowing',
    component: () => import('../components/nft/Borrowing.vue'),
  	meta: {
  		keepAlive: true, // 组件需要缓存
  	}
  },
  {
    path: '/nft/Jie',
    name: 'jie',
    component: () => import('../components/nft/Jie.vue'),
  },
  {
    path: '/nft/Jielog',
    name: 'jielog',
    component: () => import('../components/nft/Jielog.vue'),
  },
  {
    path: '/nft/Otcs1',
    name: 'otcs1',
    component: () => import('../components/nft/Otcs1.vue'),
  },
  {
    path: '/user/account',
    name: 'account',
    component: () => import('../components/user/Account.vue'),
  },
  {
    path: '/user/accountedit',
    name: 'accountedit',
    component: () => import('../components/user/Accountedit.vue'),
  },
  {
    path: '/user/logs',
    name: 'logs',
    component: () => import('../components/user/Logs.vue')
  },
  {
    path: '/nft/shoplogs',
    name: 'shoplogs',
    component: () => import('../components/nft/Shoplogs.vue')
  },
  {
    path: '/user/logs1',
    name: 'logs1',
    component: () => import('../components/user/Logs1.vue')
  },
  {
    path: '/user/system',
    name: 'system',
    component: () => import('../components/user/System.vue')
  },
  {
    path: '/user/system1',
    name: 'system1',
    component: () => import('../components/user/System1.vue'),
	meta: {
		keepAlive: true, // 组件需要缓存
	}
  },
  {
    path: '/user/system2',
    name: 'system2',
    component: () => import('../components/user/System2.vue'),
	meta: {
		keepAlive: true, // 组件需要缓存
	}
  },
  {
    path: '/nft/buy',
    name: 'buy',
    component: () => import('../components/nft/Buy.vue')
  },
  {
    path: '/nft/order',
    name: 'order',
    component: () => import('../components/nft/Order.vue')
  },
  {
    path: '/nft/sell',
    name: 'sell',
    component: () => import('../components/nft/Sell.vue')
  },
  {
    path: '/user/verified',
    name: 'verified',
    component: () => import('../components/user/Verified.vue'),
  	meta: {
  		keepAlive: true, // 组件需要缓存
  	}
  },
  {
    path: '/user/verified1',
    name: 'verified1',
    component: () => import('../components/user/Verified1.vue'),
  	meta: {
  		keepAlive: true, // 组件需要缓存
  	}
  },
  {
    path: '/user/kyc',
    name: 'kyc',
    component: () => import('../components/user/Kyc.vue'),
  },
  {
    path: '/nft/search',
    name: 'search',
    component: () => import('../components/nft/Search.vue'),
  	meta: {
  		keepAlive: true, // 组件需要缓存
  	}
  },
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // },
  
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})


router.beforeEach((to, from, next) => {
	let token = localStorage.getItem('token');
	let name = ["index", "login", "repassword", "register","index1"];
	if(!token){
		if(name.toString().indexOf(to.name) > -1) {
			next();
		}else{
			router.push('/login');
		}	
	}else{
		next();
	}
});

export default router
